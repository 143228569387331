import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateTransactionsDownloadQueueMutation,
  TransactionsDownloadQueue,
  CreateTransactionsDownloadQueueMutationVariables,
  CreateTransactionsReceiptsDownloadQueueMutationVariables,
  CreateTransactionsReceiptsDownloadQueueMutation,
  TransactionsReceiptsDownloadQueue,
  PaymentStatusDownloadStatusEnum,
  ExportDateRangeEnum,
} from 'API';
import { RootState } from 'app/store/rootReducer';
import { API, graphqlOperation } from 'aws-amplify';
import { createTransactionsDownloadQueue, createTransactionsReceiptsDownloadQueue } from 'graphql/mutations';

export interface DownloadTransactionsState {
  download?: TransactionsDownloadQueue;
  receiptsDownload?: TransactionsReceiptsDownloadQueue;
}

export const initialState: DownloadTransactionsState = {
  download: undefined,
  receiptsDownload: undefined,
};

export interface PrepareDownloadArgs {
  userId: string;
  paidolId: string;
  columns: string[];
  startDate?: Date;
  endDate?: Date;
  paidolUserId?: string;
  rangeOption: ExportDateRangeEnum;
}

export interface PrepareReceiptsDownloadArgs {
  userId: string;
  paidolId: string;
  paidolUserId?: string;
}

// This function is for spreadsheet data download
export const prepareDownload = createAsyncThunk(
  'overview/transactions/prepareDownload',
  async (
    { userId, paidolId, columns, startDate, endDate, paidolUserId, rangeOption }: PrepareDownloadArgs,
    { dispatch }
  ) => {
    let formattedStartDate = '';
    let formattedEndDate = '';

    if (startDate) {
      formattedStartDate = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`;
    }

    if (endDate) {
      formattedEndDate = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`;
    }

    const input: CreateTransactionsDownloadQueueMutationVariables = {
      input: {
        user_id: userId,
        paidol_id: paidolId,
        columns: columns,
        range_option: rangeOption,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        paidolUserId,
      },
    };

    return (
      API.graphql(graphqlOperation(createTransactionsDownloadQueue, input)) as Promise<
        GraphQLResult<CreateTransactionsDownloadQueueMutation>
      >
    ).then((results) => {
      if (results?.data?.createTransactionsDownloadQueue) {
        dispatch(setDownload(results?.data?.createTransactionsDownloadQueue));
      }
    });
  }
);

export const prepareReceiptsDownload = createAsyncThunk(
  'overview/transactions/prepareReceiptsDownload',
  async ({ userId, paidolId, paidolUserId }: PrepareReceiptsDownloadArgs, { dispatch }) => {
    const input: CreateTransactionsReceiptsDownloadQueueMutationVariables = {
      input: {
        user_id: userId,
        paidol_id: paidolId,
        paidolUserId,
        cron_status: PaymentStatusDownloadStatusEnum.QUEUED,
      },
    };

    return (
      API.graphql(graphqlOperation(createTransactionsReceiptsDownloadQueue, input)) as Promise<
        GraphQLResult<CreateTransactionsReceiptsDownloadQueueMutation>
      >
    ).then((results) => {
      if (results?.data?.createTransactionsReceiptsDownloadQueue) {
        dispatch(setReceiptsDownload(results?.data?.createTransactionsReceiptsDownloadQueue));
      }
    });
  }
);

const downloadTransactionsSlice = createSlice({
  name: 'overview/transactions/downloadTransactions',
  initialState,
  reducers: {
    resetDownloadTransactions: (state) => {
      state = initialState;
    },
    setDownload: (state, action: PayloadAction<TransactionsDownloadQueue | undefined>) => {
      state.download = action.payload;
    },
    setReceiptsDownload: (state, action: PayloadAction<TransactionsReceiptsDownloadQueue | undefined>) => {
      state.receiptsDownload = action.payload;
    },
  },
});

export const selectDownloadTransactionsSlice = (state: RootState) =>
  state.cards?.downloadTransactions ?? initialState;

export const { resetDownloadTransactions, setDownload, setReceiptsDownload } =
  downloadTransactionsSlice.actions;

export default downloadTransactionsSlice.reducer;
