import type { RootState } from 'app/store/rootReducer';
import { signIn, getCurrentUser } from './authSlice';
import { User } from 'API';
import { getUser, updateUser, completeFirstRun as completeFirstRunMutation } from './userProfileQueries';
import type { GetUserQuery, UpdateUserMutation } from './userProfileQueries';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import type { GraphQLResult } from '@aws-amplify/api-graphql';
import serializeError, { ErrorLike } from 'util/serializeError';
import type { CompleteFirstRunInput, CompleteFirstRunPayload, UpdateUserInput } from 'API';

export type Language = 'en' | 'es';

export interface UserProfileState {
  isUserProfileStateUnknown: boolean;
  profile?: User;
  picture?: string;
  language: Language;
}

const initialState: UserProfileState = {
  isUserProfileStateUnknown: true,
  language: (localStorage.getItem('i18nextLng') as Language) || 'en',
};

export const getUserProfile = createAsyncThunk<User | null | undefined, string, { rejectValue: ErrorLike }>(
  'userProfile/getUserProfile',
  async (userId: string, { rejectWithValue, dispatch }) => {
    const query = API.graphql(graphqlOperation(getUser, { id: userId })) as Promise<
      GraphQLResult<GetUserQuery>
    >;
    return query
      .then((response) => {
        const profile = response.data?.getUser;

        if (profile) {
          if (profile.picture) {
            dispatch(getUserPicture(profile.picture));
          }

          return profile;
        }
      })
      .catch((error) => rejectWithValue(serializeError(error)));
  }
);

export const getUserPicture = createAsyncThunk<string, string, { rejectValue: ErrorLike }>(
  'userProfile/getUserPicture',
  async (picture: string, { rejectWithValue }) => {
    return Storage.get(picture, { level: 'public', expires: 86400 }).catch((error) =>
      rejectWithValue(serializeError(error))
    );
  }
);

interface SetUserPictureArgs {
  id: string;
  fileName: string;
  file: string;
}

export const setUserPicture = createAsyncThunk(
  'userProfile/uploadProfilePicture',
  async ({ id, fileName, file }: SetUserPictureArgs, { dispatch }) => {
    return Storage.put(fileName, file).then(() => {
      dispatch(updateUserProfile({ id, picture: fileName }));
      dispatch(getUserPicture(fileName));
    });
  }
);

export const updateUserProfile = createAsyncThunk<
  User | null | undefined,
  UpdateUserInput,
  { rejectValue: ErrorLike }
>('userProfile/updateUserProfile', async (args, { rejectWithValue }) => {
  const query = API.graphql(graphqlOperation(updateUser, { input: args })) as Promise<
    GraphQLResult<UpdateUserMutation>
  >;
  return query
    .then((response) => {
      const profile = response.data?.updateUser;

      if (profile) {
        return profile;
      }
    })
    .catch((error) => rejectWithValue(serializeError(error)));
});

export const completeFirstRun = createAsyncThunk(
  'userProfile/completeFirstRun',
  async (input: CompleteFirstRunInput, { dispatch }) => {
    return (
      API.graphql(graphqlOperation(completeFirstRunMutation, { input })) as Promise<
        GraphQLResult<CompleteFirstRunPayload>
      >
    ).then((results) => results?.data);
  }
);

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    resetUserProfileSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserPicture.fulfilled, (state, action) => {
      if (action.payload) {
        state.picture = action.payload;
      }
    });

    builder.addCase(completeFirstRun.fulfilled, (state, action) => {
      const { data, operationName } = action.meta.arg;

      if (operationName === 'completeFirstRunChecklist' && state.profile?.firstRunChecklist) {
        state.profile.firstRunChecklist = {
          ...state.profile.firstRunChecklist,
          [data.firstRunValue]: true,
        };
      } else if (operationName === 'completeFirstRunBanner' && state.profile?.firstRunBanner) {
        state.profile.firstRunBanner = {
          ...state.profile.firstRunBanner,
          [data.firstRunValue]: true,
        };
      }
    });

    builder.addMatcher(isAnyOf(getUserProfile.fulfilled, updateUserProfile.fulfilled), (state, action) => {
      if (action.payload) {
        state.profile = action.payload;
        state.isUserProfileStateUnknown = false;
      }
    });

    builder.addMatcher(isAnyOf(getCurrentUser.rejected, signIn.rejected), (state) => {
      state.profile = undefined;
      state.picture = undefined;
      state.isUserProfileStateUnknown = true;
    });
  },
});

export const { setLanguage } = userProfileSlice.actions;

export const { resetUserProfileSlice } = userProfileSlice.actions;

export const selectLanguage = (state: RootState): Language => state.userProfile.language;

export const selectUserProfile = (state: RootState): UserProfileState =>
  state.userProfile as UserProfileState;

export default userProfileSlice.reducer;
