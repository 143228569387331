import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  Paidol,
  PaidolUser,
  PaidolUserByUserIdQuery,
  Role,
  UpdatePaidolUserInput,
  UpdatePaidolUserMutation,
  User,
} from 'API';
import type { RootState } from 'app/store/rootReducer';
import { getCurrentUser, signIn, signOut } from 'app/store/authSlice';
import { API, graphqlOperation } from 'aws-amplify';
import { paidolUserByUserId } from './userCompaniesQueries';
import { clearCompanySelection, getCompanySelection, setCompanySelection } from 'util/helpers';
import { isNotNullOrUndefined, isPaidolUser } from 'util/typeGuards';
import { updatePaidolUser as updatePaidolUserMutation } from 'graphql/mutations';
import { resetFundingSlice } from 'app/pages/store/fundingSlice';

export interface UserCompaniesState {
  isUserCompaniesStateUnknown: boolean;
  isUserOnboarded: boolean;
  paidolUsers: Array<PaidolUser>;
  selectedPaidolId: string;
  selectedPaidol?: Paidol;
  highnotePaymentCardIds: Array<string>;
  userRole?: Role;
  user?: User;
}

export const initialState: UserCompaniesState = {
  isUserCompaniesStateUnknown: true,
  highnotePaymentCardIds: [],
  isUserOnboarded: false,
  paidolUsers: [],
  selectedPaidolId: '',
  selectedPaidol: undefined,
  user: undefined,
};

export const getUserCompanies = createAsyncThunk<PaidolUser[], string>(
  'userCompanies/getUserCompanies',
  async (user_id, { dispatch }) => {
    return (
      API.graphql(
        graphqlOperation(paidolUserByUserId, {
          user_id,
        })
      ) as Promise<GraphQLResult<PaidolUserByUserIdQuery>>
    ).then((response) => {
      const paidolUsers = response.data?.paidolUserByUserId?.items.filter(isPaidolUser) || [];
      const validPaidolIds = paidolUsers
        .map((paidolUser) => paidolUser.paidol?.id)
        .filter(isNotNullOrUndefined);
      const memoizedCompanySelection = getCompanySelection();

      if (validPaidolIds.length) {
        if (validPaidolIds.includes(memoizedCompanySelection)) {
          dispatch(setSelectedPaidolId(memoizedCompanySelection));
        } else {
          dispatch(setSelectedPaidolId(validPaidolIds[0]));
        }
      } else {
        clearCompanySelection();
      }

      return paidolUsers;
    });
  }
);

export const setSelectedPaidolId = createAsyncThunk<string, string>(
  'userCompanies/setSelectedPaidolId',
  async (paidol_id: string, { dispatch }) => {
    return new Promise((resolve) => {
      setCompanySelection(paidol_id);
      dispatch(resetFundingSlice());
      return resolve(paidol_id);
    });
  }
);

export const updatePaidolUser = createAsyncThunk(
  'userCompanies/updatePaidolUser',
  async (input: UpdatePaidolUserInput) => {
    return (
      API.graphql(
        graphqlOperation(updatePaidolUserMutation, {
          input,
        })
      ) as Promise<GraphQLResult<UpdatePaidolUserMutation>>
    ).then((results) => results?.data?.updatePaidolUser);
  }
);

const userCompaniesSlice = createSlice({
  name: 'userCompanies',
  initialState,
  reducers: {
    resetUserCompaniesSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserCompanies.fulfilled, (state, action) => {
      state.isUserCompaniesStateUnknown = false;
      state.isUserOnboarded = action.payload && action.payload.length > 0;
      state.paidolUsers = action.payload;
      state.user = action.payload.length && action.payload[0].user ? action.payload[0].user : undefined;
    });

    builder.addCase(updatePaidolUser.fulfilled, (state, action) => {
      const [paidolUser] = state.paidolUsers;
      state.paidolUsers = [
        {
          ...paidolUser,
          ...(action.payload as PaidolUser),
        },
      ];
    });

    builder.addCase(setSelectedPaidolId.fulfilled, (state, action) => {
      const selectedPaidolUser = state.paidolUsers.find(
        (paidolUser) => paidolUser.paidol?.id === action.payload
      );

      if (selectedPaidolUser && selectedPaidolUser.paidol) {
        state.highnotePaymentCardIds =
          selectedPaidolUser.paymentCards?.items
            .filter((i) => isNotNullOrUndefined(i))
            .map((i) => i!.highnotePaymentCardID) ?? [];
        state.selectedPaidol = {
          ...selectedPaidolUser.paidol,
          enablePayables: selectedPaidolUser.paidol.enablePayables ?? true,
          enablePCards: selectedPaidolUser.paidol.enablePCards ?? false,
          low_balance_alert_threshold: selectedPaidolUser.paidol.low_balance_alert_threshold ?? 0,
          isConstructionType: selectedPaidolUser.paidol.isConstructionType ?? false,
          isAgaveClient: selectedPaidolUser.paidol.isAgaveClient ?? false,
          agaveAccountToken: selectedPaidolUser.paidol.agaveAccountToken ?? '',
        };
        state.selectedPaidolId = action.payload;
        state.userRole = selectedPaidolUser.roles ?? Role.CONTRIBUTOR;
      }
    });

    builder.addMatcher(isAnyOf(getUserCompanies.pending, signIn.pending), (state) => {
      state.isUserCompaniesStateUnknown = true;
    });

    builder.addMatcher(
      isAnyOf(getUserCompanies.rejected, signIn.rejected, getCurrentUser.rejected, signOut.fulfilled),
      (state) => {
        state.isUserCompaniesStateUnknown = false;
        state.isUserOnboarded = false;
        state.paidolUsers = [];
        state.selectedPaidol = undefined;
        state.selectedPaidolId = '';
        state.user = undefined;
        state.userRole = undefined;
      }
    );
  },
});

export const { resetUserCompaniesSlice } = userCompaniesSlice.actions;

export const selectUserCompanies = (state: RootState) => state.userCompanies as UserCompaniesState;

export default userCompaniesSlice.reducer;
