import { GraphQLResult } from '@aws-amplify/api-graphql';
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import {
  AgaveCostTypeInput,
  AgaveCostCodeInput,
  AgaveVendorInput,
  AgaveProjectInput,
  GetProcoreProjectsPayloadInput,
  GetProcoreWBSCodesPayloadInput,
  HighnoteTransaction,
  SearchHighnoteTransactionsQuery,
  SearchHighnoteTransactionsQueryVariables,
  SearchableHighnoteTransactionConnection,
  SearchableHighnoteTransactionSortableFields,
  SearchableSortDirection,
  UpdateHighnoteTransactionMutation,
  UpdateHighnoteTransactionMutationVariables,
  CreateAgaveExpenseAPInvoice,
  CreateAgaveExpenseAPInvoiceInput,
  SearchableHighnoteTransactionFilterInput,
} from 'API';
import { RootState } from 'app/store/rootReducer';
import { API, graphqlOperation } from 'aws-amplify';
import { endOfMonth, formatISO, startOfMonth } from 'date-fns';
import { updateHighnoteTransaction } from 'graphql/mutations';
import { isNotNullOrUndefined } from 'util/typeGuards';
import { getCardDetails } from './paymentCardsSlice';
import { SearchHighnoteTransactions } from './queries';
import { createOrUpdateDirectCost } from './procoreSlice';
import { SortOption } from 'app/store/searchAndFilterSlice';
import { customUpdateHighnoteTransaction } from 'graphql_custom/mutations';

const entityAdapter = createEntityAdapter<HighnoteTransaction>({
  selectId: (transaction) => transaction.transactionId,
});

const initialState = entityAdapter.getInitialState({
  nextToken: undefined as string | undefined,
  total: 0,
  loading: false,
  loadingMore: false,
});

export type TransactionsState = typeof initialState;

export interface GetTransactionsArgs {
  paidolId: string;
  paymentCardId?: string;
  paymentCardIds?: string[];
  nextToken?: string;
  filterDate?: Date | null;
  searchTerms?: string | null;
  sortField?: SearchableHighnoteTransactionSortableFields;
  sortOrder?: SearchableSortDirection;
  shouldGetCardDetails?: boolean;
}

export interface UpdateTransactionArgs {
  id: string;
  note?: string | undefined;
  jobCodeId?: string | null;
  procoreProject?: GetProcoreProjectsPayloadInput | null;
  procoreWBSCode?: GetProcoreWBSCodesPayloadInput | null;
  agaveProject?: AgaveProjectInput | null;
  agaveCostCode?: AgaveCostCodeInput | null;
  agaveCostType?: AgaveCostTypeInput | null;
  agaveVendor?: AgaveVendorInput | null;
  agaveExpense?: CreateAgaveExpenseAPInvoice | null;
  receiptPath?: string | undefined;
  receiptId?: string | undefined;
}

export interface GetTransactionsWithFiltersArgs {
  nextToken?: string;
  filterObject?: SearchableHighnoteTransactionFilterInput;
  sortObject?: SortOption;
  shouldGetCardDetails?: boolean;
}

export const getTransactionsWithFilters = createAsyncThunk(
  'overview/transactions/GetTransactionsWithFiltersArgs',
  async (
    { filterObject, sortObject, nextToken, shouldGetCardDetails = true }: GetTransactionsWithFiltersArgs,
    { dispatch }
  ) => {
    const variables = {
      filter: filterObject,
      sort: sortObject?.field ? sortObject : undefined,
      nextToken,
      limit: 20,
    };

    return (
      API.graphql(graphqlOperation(SearchHighnoteTransactions, variables)) as Promise<
        GraphQLResult<SearchHighnoteTransactionsQuery>
      >
    ).then((result) => {
      if (shouldGetCardDetails) {
        const paymentCardIds = new Set(
          result.data?.searchHighnoteTransactions?.items
            .map((item) => item?.paymentCardId)
            .filter(isNotNullOrUndefined)
        );

        paymentCardIds.forEach((id) => {
          dispatch(getCardDetails(id));
        });
      }

      return result.data?.searchHighnoteTransactions as SearchableHighnoteTransactionConnection;
    });
  }
);

export const getTransactions = createAsyncThunk(
  'overview/transactions/getTransactions',
  async (
    {
      paidolId,
      paymentCardId,
      nextToken,
      filterDate,
      searchTerms,
      sortField = SearchableHighnoteTransactionSortableFields.transactionDate,
      sortOrder = SearchableSortDirection.desc,
      shouldGetCardDetails = true,
      paymentCardIds,
    }: GetTransactionsArgs,
    { dispatch }
  ) => {
    const variables: SearchHighnoteTransactionsQueryVariables = {
      filter: {
        paidolId: { eq: paidolId },
        status: { ne: 'REVERSED' },
        ...(paymentCardId && { paymentCardId: { eq: paymentCardId } }),
        ...(paymentCardIds && {
          or: paymentCardIds.map((id) => ({ paymentCardId: { eq: id } })),
        }),
        ...(filterDate && {
          transactionDate: {
            gt: formatISO(startOfMonth(filterDate)),
            lt: formatISO(endOfMonth(filterDate)),
          },
        }),
        ...(searchTerms && {
          or: [
            {
              merchantName: {
                match: searchTerms,
              },
            },
            {
              merchantName: {
                wildcard: `${searchTerms}*`,
              },
            },
            {
              merchantCategory: {
                match: searchTerms,
              },
            },
            {
              merchantCategory: {
                wildcard: `${searchTerms}*`,
              },
            },
          ],
        }),
      },
      sort: [
        {
          field: sortField,
          direction: sortOrder,
        },
      ],
      nextToken,
      limit: 20,
    };
    return (
      API.graphql(graphqlOperation(SearchHighnoteTransactions, variables)) as Promise<
        GraphQLResult<SearchHighnoteTransactionsQuery>
      >
    ).then((result) => {
      if (shouldGetCardDetails) {
        const paymentCardIds = new Set(
          result.data?.searchHighnoteTransactions?.items
            .map((item) => item?.paymentCardId)
            .filter(isNotNullOrUndefined)
        );

        paymentCardIds.forEach((id) => {
          dispatch(getCardDetails(id));
        });
      }

      return result.data?.searchHighnoteTransactions as SearchableHighnoteTransactionConnection;
    });
  }
);

export const updateTransaction = createAsyncThunk(
  'overview/transactions/updateTransaction',
  async ({
    id,
    note,
    jobCodeId,
    procoreProject,
    procoreWBSCode,
    agaveCostType,
    agaveCostCode,
    agaveProject,
    agaveVendor,
    agaveExpense,
    receiptPath,
    receiptId,
  }: UpdateTransactionArgs) => {
    const variables: UpdateHighnoteTransactionMutationVariables = {
      input: {
        transactionId: id,
        ...(note !== undefined && { note: note }),
        ...(jobCodeId !== undefined && { jobCodeId: jobCodeId }),
        ...(procoreProject !== undefined && {
          procoreProjectId: procoreProject?.id || null,
          procoreProject: procoreProject,
        }),
        ...(procoreWBSCode !== undefined && {
          procoreWBSCodeId: procoreWBSCode?.id || null,
          procoreWBSCode: procoreWBSCode,
        }),
        ...(agaveCostType !== undefined && {
          agaveCostTypeId: agaveCostType?.id || null,
          agaveCostType: agaveCostType,
        }),
        ...(agaveCostCode !== undefined && {
          agaveCostCodeId: agaveCostCode?.id || null,
          agaveCostCode: agaveCostCode,
        }),
        ...(agaveProject !== undefined && {
          agaveProjectId: agaveProject?.id || null,
          agaveProject: agaveProject,
        }),
        ...(agaveVendor !== undefined && {
          agaveVendorId: agaveVendor?.id || null,
          agaveVendor: agaveVendor,
        }),
        ...(agaveExpense !== undefined && {
          agaveExpenseId: agaveExpense?.id || null,
          agaveExpense: agaveExpense as CreateAgaveExpenseAPInvoiceInput | CreateAgaveExpenseAPInvoiceInput,
        }),
        ...(receiptPath !== undefined && { receiptPath: receiptPath }),
        ...(receiptId !== undefined && { receiptId: receiptId }),
      },
    };
    return (
      API.graphql(graphqlOperation(updateHighnoteTransaction, variables)) as Promise<
        GraphQLResult<UpdateHighnoteTransactionMutation>
      >
    ).then((result) => result?.data?.updateHighnoteTransaction as HighnoteTransaction);
  }
);

export const updateTransactionReceipt = createAsyncThunk(
  'overview/transactions/updateTransactionReceipt',
  async ({
    paidolId,
    receiptPath = null, // default to null for removal
    receiptId = null, // default to null for removal
    transactionId,
  }: {
    transactionId: string;
    paidolId: string;
    receiptPath?: string | null;
    receiptId?: string | null;
  }) => {
    const variables: UpdateHighnoteTransactionMutationVariables = {
      input: {
        transactionId: transactionId,
        authPaidolId: paidolId,
        paidolId,
        receiptPath, // explicitly pass null if undefined to remove the field
        receiptId,
      },
    };
    return (
      API.graphql(graphqlOperation(customUpdateHighnoteTransaction, variables)) as Promise<
        GraphQLResult<UpdateHighnoteTransactionMutation>
      >
    ).then((result) => result?.data?.updateHighnoteTransaction as HighnoteTransaction);
  }
);

const transactionsSlice = createSlice({
  name: 'overview/transactions',
  initialState,
  reducers: {
    resetTransactionsSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTransactions.pending, (state, action) => {
      if (action.meta.arg.nextToken) {
        state.loadingMore = true;
      } else {
        state.loading = true;
      }
    });

    builder.addCase(getTransactionsWithFilters.pending, (state, action) => {
      if (action.meta.arg.nextToken) {
        state.loadingMore = true;
      } else {
        state.loading = true;
      }
    });

    builder.addCase(getTransactionsWithFilters.fulfilled, (state, action) => {
      if (action.payload?.items) {
        const transactions = action.payload.items
          .filter(isNotNullOrUndefined)
          .map((i) => i as HighnoteTransaction);

        if (!action.meta.arg.nextToken) {
          // If nextToken not specified, this is first
          // page of results. Clear out old results.
          entityAdapter.removeAll(state);
        }

        entityAdapter.addMany(state, transactions);
      }

      state.nextToken = action.payload?.nextToken ?? undefined;
      state.total = action.payload?.total ?? 0;
      state.loading = false;
      state.loadingMore = false;
    });

    builder.addCase(getTransactions.fulfilled, (state, action) => {
      if (action.payload?.items) {
        const transactions = action.payload.items
          .filter(isNotNullOrUndefined)
          .map((i) => i as HighnoteTransaction);

        if (!action.meta.arg.nextToken) {
          // If nextToken not specified, this is first
          // page of results. Clear out old results.
          entityAdapter.removeAll(state);
        }

        entityAdapter.addMany(state, transactions);
      }

      state.nextToken = action.payload?.nextToken ?? undefined;
      state.total = action.payload?.total ?? 0;
      state.loading = false;
      state.loadingMore = false;
    });

    builder.addCase(getTransactions.rejected, (state) => {
      state.loading = false;
      state.loadingMore = false;
    });

    builder.addCase(updateTransactionReceipt.fulfilled, (state, action) => {
      const transaction = state.entities[action.payload.transactionId];

      if (transaction) {
        transaction.receiptPath = action.payload.receiptPath;
        transaction.receiptId = action.payload.receiptId;
      }
    });

    builder.addCase(updateTransactionReceipt.rejected, (state, action) => {
      //error handling for rejected updateTransactionReceip
      console.error('Failed to update transaction receipt:', action.error);
    });

    builder.addCase(updateTransaction.fulfilled, (state, action) => {
      if (action.payload?.transactionId) {
        const changes: any = {};

        if (action.meta.arg.procoreProject !== undefined) {
          changes['procoreProject'] = action.payload.procoreProject;
          changes['procoreProjectId'] = action.payload.procoreProjectId;
        }

        if (action.meta.arg.procoreWBSCode !== undefined) {
          changes['procoreWBSCode'] = action.payload.procoreWBSCode;
          changes['procoreWBSCodeId'] = action.payload.procoreWBSCodeId;
        }

        if (action.meta.arg.agaveCostCode !== undefined) {
          changes['agaveCostCode'] = action.payload.agaveCostCode;
          changes['agaveCostCodeId'] = action.payload.agaveCostCodeId;
        }

        if (action.meta.arg.agaveCostType !== undefined) {
          changes['agaveCostType'] = action.payload.agaveCostType;
          changes['agaveCostTypeId'] = action.payload.agaveCostTypeId;
        }

        if (action.meta.arg.agaveProject !== undefined) {
          changes['agaveProject'] = action.payload.agaveProject;
          changes['agaveProjectId'] = action.payload.agaveProjectId;
        }

        if (action.meta.arg.agaveVendor !== undefined) {
          changes['agaveVendor'] = action.payload.agaveVendor;
          changes['agaveVendorId'] = action.payload.agaveVendorId;
        }

        if (action.meta.arg.agaveExpense !== undefined) {
          changes['agaveExpense'] = action.payload.agaveExpense;
          changes['agaveExpenseId'] = action.payload.agaveExpenseId;
        }

        if (action.meta.arg.receiptPath !== undefined) {
          changes['receiptPath'] = action.payload.receiptPath;
        }

        if (Object.entries(changes).length > 0) {
          entityAdapter.updateOne(state, {
            id: action.payload.transactionId,
            changes,
          });
        }
      }
    });

    builder.addCase(createOrUpdateDirectCost.fulfilled, (state, action) => {
      if (action.payload?.transactionId) {
        const changes: any = {};

        if (action.payload.directCostId !== undefined) {
          changes['procoreDirectCostId'] = action.payload.directCostId;
        }

        if (action.payload.directCostLineItemId !== undefined) {
          changes['procoreDirectCostLineItemId'] = action.payload.directCostLineItemId;
        }

        if (Object.entries(changes).length > 0) {
          entityAdapter.updateOne(state, {
            id: action.payload.transactionId,
            changes,
          });
        }
      }
    });
  },
});

export const { resetTransactionsSlice } = transactionsSlice.actions;

export const selectTransactionsSlice = (state: RootState): TransactionsState =>
  state?.cards?.transactions ?? initialState;

export const { selectAll: selectAllTransactions } = entityAdapter.getSelectors(selectTransactionsSlice);

export default transactionsSlice.reducer;
