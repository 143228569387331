import { Box, Button, Container, styled, Typography } from '@mui/material';
import { useCreateSpendRule } from 'app/hooks/spend-rule/useCreateSpendRule';
import SpendRulesForm, { EditSpendRuleFormValues } from 'app/pages/cards/spend-rules/SpendRulesForm';
import ContainerComponent from 'app/shared-components/container-component/container-component';
import ProgressButton from 'app/shared-components/ui/ProgressButton';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

const StyledImg = styled('img')({
  maxWidth: '37rem',
  alignSelf: 'flex-end',
});

const StyledContainer = styled(Container)({
  gap: '5rem',
  display: 'flex',
  justifyContent: 'normal',
  alignItems: 'center',
  maxWidth: '140rem',
  flexWrap: 'wrap',
});

type CreateSpendRuleFormProps = {
  handleNext: () => void;
};

function CreateSpendRuleForm({ handleNext }: CreateSpendRuleFormProps) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const createSpendRuleHandler = useCreateSpendRule();

  const onSubmit = useCallback(
    async (formValues: EditSpendRuleFormValues) => {
      setLoading(true);
      await createSpendRuleHandler(formValues);
      setLoading(false);
      handleNext();
    },
    [createSpendRuleHandler, handleNext]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      width="100%"
    >
      <ContainerComponent>
        <StyledContainer>
          <Typography maxWidth="24rem" variant="body1">
            {t('createSpendRuleHelperText')}
          </Typography>
          <StyledImg
            alt="Illustration of a person making a gesture"
            src={`${process.env.PUBLIC_URL}/assets/illustrations/humans/human-gesture.svg`}
          />
        </StyledContainer>
      </ContainerComponent>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        width="100%"
        maxWidth="38rem"
      >
        <SpendRulesForm
          renderButtons={({ handleSubmit, isValid, isDirty }) => (
            <>
              <Button onClick={handleNext}>{t('illDoThisLater')}</Button>
              <ProgressButton
                loading={loading}
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid || !isDirty || loading}
              >
                {t('useTheseLimits')}
              </ProgressButton>
            </>
          )}
        />
      </Box>
    </Box>
  );
}

export default CreateSpendRuleForm;
